import { useStaticQuery, graphql } from "gatsby"

const virtualPageDataQuery = graphql`
  query VirtualPageData {
    sanityVirtualPage {
      ...fieldsOnVirtualPageDocument
    }
  }
`

export const useVirtualPage = () => {
  const { sanityVirtualPage } = useStaticQuery(virtualPageDataQuery)

  return sanityVirtualPage
}

export default useVirtualPage
