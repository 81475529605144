import { useStaticQuery, graphql } from "gatsby"

const podcastsPageDataQuery = graphql`
  query PodcastsPageData {
    sanityPodcastsPage {
      ...fieldsOnPodcastsPageDocument
    }
  }
`

export const usePodcastsPage = () => {
  const { sanityPodcastsPage } = useStaticQuery(podcastsPageDataQuery)

  return sanityPodcastsPage
}

export default usePodcastsPage
