import { useStaticQuery, graphql } from "gatsby"

const partnersPageDataQuery = graphql`
  query PartnersPageData {
    sanityPartnersPage {
      ...fieldsOnPartnersPageDocument
    }
  }
`

export const usePartnersPage = () => {
  const { sanityPartnersPage } = useStaticQuery(partnersPageDataQuery)


  return sanityPartnersPage
}

export default usePartnersPage
