import { useStaticQuery, graphql } from "gatsby"
import moment from 'moment'

const editPostsDataQuery = graphql`
  query EditPostsData {
    allSanityEditPost(
      filter: { publish: { eq: true } }
      sort: { fields: publicationDate, order: ASC }
    ) {
      nodes {
        ...fieldsOnEditPostDocument
      }
    }
  }
`

export const useEditPosts = () => {
  const {
    allSanityEditPost: { nodes },
  } = useStaticQuery(editPostsDataQuery)


  return Object.values(nodes).reverse()
}

export default useEditPosts
