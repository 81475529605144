import { useStaticQuery, graphql } from "gatsby"

const galleriesPageDataQuery = graphql`
  query GalleriesPageData {
    sanityGalleriesPage {
      ...fieldsOnGalleriesPageDocument
    }
  }
`

export const useGalleriesPage = () => {
  const { sanityGalleriesPage } = useStaticQuery(galleriesPageDataQuery)

  return sanityGalleriesPage
}

export default useGalleriesPage
