import { useStaticQuery, graphql } from "gatsby"

const newsPageDataQuery = graphql`
  query NewsPageData {
    sanityNewsPage {
      ...fieldsOnNewsPageDocument
    }
  }
`

export const useNewsPage = () => {
  const { sanityNewsPage } = useStaticQuery(newsPageDataQuery)

  return sanityNewsPage
}

export default useNewsPage
