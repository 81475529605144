import union from "lodash.union"

/* Import Global Hooks */
import { useGalleries } from "./useGalleries"

/* Only Get Years that are associated with Galleries*/

export const useGalleryYears = filterYear => {
  const galleries = useGalleries()

  let years = galleries.reduce((accumulator, gallery) => {
    const galleryYears = gallery.years.map(({ year }) => year)
    return union(accumulator, galleryYears)
  }, [])

  /* sort in ascending order*/
  if(years.length > 1){
    years = years.map(y => parseInt(y)).sort().reverse()
  }
  

  return years
}

export default useGalleryYears
