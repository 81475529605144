import { useStaticQuery, graphql } from "gatsby"

const aboutPageDataQuery = graphql`
  query AboutPageData {
    sanityAboutPage {
      ...fieldsOnAboutPageDocument
    }
  }
`

export const useAboutPage = () => {
  const { sanityAboutPage } = useStaticQuery(aboutPageDataQuery)

  // Filter out Unpublished Partners
  sanityAboutPage.partnerTypes.forEach(partnerType => {
    partnerType.partners = partnerType.partners.filter(partner => {
      return partner.publish
    })
  });
  
  // Filter out Unpublished Partner Types and Partner Types with No Published Partners 
  sanityAboutPage.partnerTypes = sanityAboutPage.partnerTypes.filter(partnerType => {
    return partnerType.publish && partnerType.partners && partnerType.partners.length > 0
  })


  return sanityAboutPage
}

export default useAboutPage
