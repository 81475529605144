import { useStaticQuery, graphql } from "gatsby"

const programEventsDataQuery = graphql`
  query ProgramEventsData {
    allSanityProgramEvent(
      filter: { publish: { eq: true }}
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        ...fieldsOnProgramEventDocument
      }
    }
  }
`

// vip: { eq: false } 

export const useProgramEvents = () => {
  const {
    allSanityProgramEvent: { nodes },
  } = useStaticQuery(programEventsDataQuery)

  return nodes
}

export default useProgramEvents
