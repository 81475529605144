import { useStaticQuery, graphql } from "gatsby"

const programPageDataQuery = graphql`
  query ProgramPageData {
    sanityProgramPage {
      ...fieldsOnProgramPageDocument
    }
  }
`

export const useProgramPage = () => {
  const { sanityProgramPage } = useStaticQuery(programPageDataQuery)

  return sanityProgramPage
}

export default useProgramPage
