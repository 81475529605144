import { useStaticQuery, graphql } from "gatsby"

const guidePageDataQuery = graphql`
  query GuidePageData {
    sanityGuidePage {
      ...fieldsOnGuidePageDocument
    }
  }
`

export const useGuidePage = () => {
  const { sanityGuidePage } = useStaticQuery(guidePageDataQuery)

  return sanityGuidePage
}

export default useGuidePage
