import { useStaticQuery, graphql } from "gatsby"
import moment from 'moment'

const podcastsDataQuery = graphql`
  query PodcastsData {
    allSanityPodcast(
      filter: { publish: { eq: true } }
      sort: { fields: episode, order: DESC }
    ) {
      nodes {
        ...fieldsOnPodcastDocument
      }
    }
  }
`

export const usePodcasts = () => {
  const {
    allSanityPodcast: { nodes },
  } = useStaticQuery(podcastsDataQuery)

  let postsByYear = {}
  nodes.forEach(post => {
    const y = moment(post.publicationDate).format('YYYY')
    post.year = y
    if(postsByYear[y] === undefined){
      postsByYear[y] = {
        year: y,
        posts: [post]
      }      
    }else{
      postsByYear[y].posts.push(post)
    }
  })

  return Object.values(postsByYear).reverse()

  return nodes
}

export default usePodcasts