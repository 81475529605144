import { useStaticQuery, graphql } from "gatsby"

const hotelPageDataQuery = graphql`
  query HotelPageData {
    sanityHotelPage {
      ...fieldsOnHotelPageDocument
    }
  }
`

export const useHotelPage = () => {
  const { sanityHotelPage } = useStaticQuery(hotelPageDataQuery)

  return sanityHotelPage
}

export default useHotelPage
