import groupBy from "lodash.groupby"
import map from "lodash.map"
import { parseISO } from "date-fns"
import { compareAsc, isBefore, isEqual } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"

/* Import Global Hooks */
import { useProgramEvents } from "./useProgramEvents"

// Uses 1.29 of date-fns formatting
const formatWithTimezone = (date, displayFormat) => {
  const storedDate = new Date(date)
  const timeZone = "America/New_York"
  const displayedTime = formatToTimeZone(storedDate, displayFormat, {
    timeZone,
  })
  return `${displayedTime}`
}

export const useProgramEventsSortedByDay = () => {
  const programEvents = useProgramEvents()

  // Get Day of Year for Events to Group By
  programEvents.forEach(programEvent => {
    programEvent.startDayOfYear = formatWithTimezone(
      parseISO(programEvent.startDatetime),
      "dddd D MMM"
    )
  })

  // Sort Events in the day by Time Started & If End-Time is Present
  return map(
    groupBy(programEvents, "startDayOfYear"),
    (events, startDayOfYear) => {
      // Sort Events by Start Time
      let sortedEvents = events.sort((a, b) => {
        return compareAsc(new Date(a.startDatetime), new Date(b.startDatetime))
      })
      // Events with no Endtime but Same Start Time Sorted Earlier
      sortedEvents = sortedEvents.sort((a, b) => {
        if (
          isEqual(new Date(a.startDatetime), new Date(b.startDatetime)) &&
          !b.endtime
        ) {
          return 1
        } else {
          return 0
        }
      })
      // Events with Same Starttime and Both have Endtimes Sort Earlier Endtime Earlier
      sortedEvents = sortedEvents.sort((a, b) => {
        if (
          isEqual(new Date(a.startDatetime), new Date(b.startDatetime)) &&
          b.endtime &&
          isBefore(new Date(a.endDatetime), new Date(b.endDatetime))
        ) {
          return 1
        } else {
          return 0
        }
      })
      return { date: events[0].startDatetime, events: sortedEvents }
    }
  )
}

export default useProgramEventsSortedByDay
