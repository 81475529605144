import { useStaticQuery, graphql } from "gatsby"

const vipPageDataQuery = graphql`
  query VipPageData {
    sanityVipPage {
      ...fieldsOnVipPageDocument
    }
  }
`

export const useVipPage = () => {
  const { sanityVipPage } = useStaticQuery(vipPageDataQuery)

  return sanityVipPage
}

export default useVipPage
