import { useStaticQuery, graphql } from "gatsby"

const websiteConfigurationDataQuery = graphql`
  query WebsiteConfigurationData {
    sanityWebsiteConfiguration {
      ...fieldsOnWebsiteConfigurationDocument
    }
  }
`

export const useWebsiteConfiguration = () => {
  const { sanityWebsiteConfiguration } = useStaticQuery(
    websiteConfigurationDataQuery
  )

  return sanityWebsiteConfiguration
}

export default useWebsiteConfiguration
