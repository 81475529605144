import { useStaticQuery, graphql } from "gatsby"
import moment from 'moment'

const newsPostsDataQuery = graphql`
  query NewsPostsData {
    allSanityNewsPost(
      filter: { publish: { eq: true } }
      sort: { fields: publicationDate, order: DESC }
    ) {
      nodes {
        ...fieldsOnNewsPostDocument
      }
    }
  }
`

export const useNewsPosts = () => {
  const {
    allSanityNewsPost: { nodes },
  } = useStaticQuery(newsPostsDataQuery)

  let postsByYear = {}
  nodes.forEach(post => {
    const y = moment(post.publicationDate).format('YYYY')
    post.year = y
    if(postsByYear[y] === undefined){
      postsByYear[y] = {
        year: y,
        posts: [post]
      }      
    }else{
      postsByYear[y].posts.push(post)
    }
  })

  return Object.values(postsByYear).reverse()
}

export default useNewsPosts
