import { useStaticQuery, graphql } from "gatsby"

const visitPageDataQuery = graphql`
  query VisitPageData {
    sanityVisitPage {
      ...fieldsOnVisitPageDocument
    }
  }
`

export const useVisitPage = () => {
  const { sanityVisitPage } = useStaticQuery(visitPageDataQuery)

  return sanityVisitPage
}

export default useVisitPage
