import { useStaticQuery, graphql } from "gatsby"

const homePageDataQuery = graphql`
  query HomePageData {
    sanityHomePage {
      ...fieldsOnHomePageDocument
    }
  }
`

export const useHomePage = () => {
  const { sanityHomePage } = useStaticQuery(homePageDataQuery)

  return sanityHomePage
}

export default useHomePage
