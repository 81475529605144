import { useStaticQuery, graphql } from "gatsby"

const programEventsVipDataQuery = graphql`
  query ProgramEventsVipData {
    allSanityProgramEvent(
      filter: { publish: { eq: true }, vip: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        ...fieldsOnProgramEventDocument
      }
    }
  }
`

export const useProgramEventsVip = () => {
  const {
    allSanityProgramEvent: { nodes },
  } = useStaticQuery(programEventsVipDataQuery)

  return nodes
}

export default useProgramEventsVip
